import moment from "moment";
import { useState, useEffect, useMemo, Fragment } from "react";
import React from "react";
import { Form, Button } from "react-bootstrap";
import './Review.css';
import './Review.scss';
import { FaFilter, FaSearch, FaFilePdf } from "react-icons/fa";
import token from './Token';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import { ReviewCalendarViewChart } from './ReviewCalendarViewChart';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import {
    Calendar,
    Views,
    DateLocalizer,
    momentLocalizer,
  } from 'react-big-calendar'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const ColoredDateCellWrapper = ({ children }) =>
React.cloneElement(React.Children.only(children), {
    style: {
    backgroundColor: 'lightblue',
    },
})

const mLocalizer = momentLocalizer(moment)


function Review() {

    useEffect(() => {
        window.document.title = "Performance Review";
    }, []);

    const localizer = mLocalizer

    const [fromDate, setFromDate] = useState(moment().startOf('day').format('YYYY-MM-DD HH:mm'));
    const [toDate, setToDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm'));
    // For the calendar view: the default date is the date that is currently in the middle of the range
    const [defDate, setDefDate] = useState(moment().startOf('day').set('hour', 2).format('YYYY-MM-DD HH:mm'));
    const [defView, setDefView] = useState('day');

    const [data, setData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [stats, setStats] = useState({});
    const [groupedByDate, setGroupedByDate] = useState({
        labels: ["01/01/2024", "02/01/2024", "03/01/2024"],
        datasets: [{
            label: 'Duration (minutes)',
            data: [10, 20, 30],
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        }]
    });

    const [searchTerms, setSearchTerms] = useState('');

    const replaceTaskIdWithSubtaskName = (data) => {
        const mData = _.cloneDeep(data);
        return mData.map(item => {
            try {
                const subtask = item.note.includes('/') ? item.note.split('/').slice(0, -1).join('/') : item.note;
                return { ...item, taskId: subtask };
            } catch (error) {
                // console.log('item: ', item);
                // note is not present in the item, we assign the taskId to "N/A"
                return { ...item, taskId: "N/A", note: "N/A" };
            }
        });
    }

    const eventFormatCast = (data, taskId = null, searchTerms = '') => {
        return data.map((item, index) => {
            const endDate = new Date(item.datetime);
            const startDate = new Date(endDate.getTime() - item.duration * 60000); // Convert duration to milliseconds

            if (taskId && item.taskId !== taskId) {
                return null;
            }

            if (searchTerms && !item.note.toLowerCase().includes(searchTerms.toLowerCase())) {
                return null;
            }

            return {
                id: index + 1,
                title: `${item.note}`,
                start: startDate,
                end: endDate,
                duration: item.duration
            };
        });
    };

    const [taskIdFilter, setTaskIdFilter] = useState(null);

    useEffect(() => {

        setGroupedByDate(groupByDate(rawData, taskIdFilter, searchTerms));
        setData(eventFormatCast(rawData, taskIdFilter, searchTerms));
        setStats(groupByTaskId(rawData, taskIdFilter, searchTerms));

    }, [taskIdFilter, searchTerms]);

    const groupByDate = (rawData, taskId = null, searchTerms = '') => {
        if (rawData.length === 0) return {};

        // Filter rawData based on taskId
        const filteredData = taskId ? rawData.filter(item => item.taskId === taskId) : rawData;

        // Filter filteredData based on searchTerms
        const filteredDataBySearchTerms = searchTerms ? filteredData.filter(item => item.note.toLowerCase().includes(searchTerms.toLowerCase())) : filteredData;

        // Find the earliest and latest dates in the rawData
        const dates = filteredDataBySearchTerms.map(item => moment(item.datetime));
        const minDate = moment.min(dates).startOf('day');
        const maxDate = moment(toDate).startOf('day');

        console.log('Max date:', maxDate);

        // Create an object with all dates initialized to 0
        const groupedData = {};
        for (let m = minDate.clone(); m.isSameOrBefore(maxDate); m.add(1, 'days')) {
            groupedData[m.format('DD/MM/YYYY')] = 0;
        }

        // Sum up the durations for each date
        filteredDataBySearchTerms.forEach(item => {
            const date = moment(item.datetime).format('DD/MM/YYYY');
            if (groupedData[date] !== undefined) {
                groupedData[date] += item.duration;
            }
        });

        return groupedData;
    };

    const queryTimerHistory = async () => {
        try {
            let datetimeISO = moment(fromDate).toISOString();
            const response = await fetch(`https://paymemobile.fr/getTimerHistoryForCalendarView?token=${token}&datetime=${datetimeISO}`);
            let rawData = await response.json();
            rawData = replaceTaskIdWithSubtaskName(rawData);
            setRawData(rawData);
            // console.log(rawData);
            setStats(groupByTaskId(rawData, taskIdFilter, searchTerms));
            // console.log(groupByTaskId(rawData));
            setGroupedByDate(groupByDate(rawData, taskIdFilter, searchTerms));
            setData(eventFormatCast(rawData, taskIdFilter, searchTerms));
            // console.log(groupByDate(rawData, taskIdFilter, searchTerms));
        } catch (error) {
            console.error("Error fetching timer history:", error);
        }
    }

    

    const setQuickDateRange = (mode) => {
        switch (mode) {
            case 'today':
                setFromDate(moment().startOf('day').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().endOf('day').format('YYYY-MM-DD HH:mm'));
                setDefDate(moment().startOf('day').format('YYYY-MM-DD HH:mm'));
                setDefView('day');
                break;
            case 'yesterday':
                setFromDate(moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm'));
                setDefDate(moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm'));
                setDefView('day');
                break;
            case 'this week':
                setFromDate(moment().startOf('week').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().endOf('week').format('YYYY-MM-DD HH:mm'));
                setDefDate(moment().startOf('week').format('YYYY-MM-DD HH:mm'));
                setDefView('week');
                break;
            case 'last week':
                setFromDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD HH:mm'));
                setDefDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD HH:mm'));
                setDefView('week');
                break;
            case 'this month':
                setFromDate(moment().startOf('month').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().endOf('month').format('YYYY-MM-DD HH:mm'));
                setDefDate(moment().startOf('month').format('YYYY-MM-DD HH:mm'));
                setDefView('week');
                break;
            case 'last month':
                setFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm'));
                setToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm'));
                setDefDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm'));
                setDefView('week');
                break;
            default:
                console.error('Invalid mode');
        }
    }

    const { components, defaultDate, max, views } = useMemo(
        () => ({
          components: {
            timeSlotWrapper: ColoredDateCellWrapper,
          },
          defaultDate: new Date(),
          max: moment().add(7, 'days').endOf('day').toDate(),
          views: Object.keys(Views).map((k) => Views[k]),
        }),
        []
    )

    const groupByTaskId = (data, taskId = null, searchTerms = '') => {
        // console.log('groupByTaskId', data);
        let totalMinutes = 0;
        let mData = _.cloneDeep(data);
        // Filter mData based on fromDate and toDate
        mData = mData.filter(item => {
            const itemDate = moment(item.datetime);
            return itemDate.isSameOrAfter(moment(fromDate)) && itemDate.isSameOrBefore(moment(toDate));
        });

        if (taskId) {
            mData = mData.filter(item => item.taskId === taskId);
        }

        if (searchTerms) {
            mData = mData.filter(item => item.note.toLowerCase().includes(searchTerms.toLowerCase()));
        }

        const groupedData = mData.reduce((acc, item) => {
          if (!acc[item.taskId]) {
            acc[item.taskId] = 0;
          }
          acc[item.taskId] += item.duration;
          totalMinutes += item.duration;
          return acc;
        }, {});

        // Convert minutes to a readable format (HH:MM)
        const formatDuration = (minutes) => {
            // convert minutes to integer
            minutes = parseInt(minutes);
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
        };

    
        return {
            totalMinutes: formatDuration(totalMinutes),
            data: Object.entries(groupedData).map(([taskId, duration]) => ({
                taskId,
                duration: Math.round(duration), // Rounding to nearest minute
                durationStr: formatDuration(Number(duration))
            }))
        };
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Daily Time Spent',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Duration (minutes)',
                },
            },
        },
    };

    const handleNavigate = (date) => {
        setDefDate(date);
    };


    const handleFromDateChange = (e) => {
        let xdString = e.target.value
        if (xdString.indexOf('d') > -1) {
            try {
                // remove the 'd' character
                xdString = xdString.replace('d', '')
                // convert to number
                let xd = Number(xdString)
                // convert to Date
                let fromDate = moment().add(xd, 'days').startOf('day').format("YYYY-MM-DD HH:mm");
                setFromDate(fromDate);
            } catch (error) {
                setFromDate(xdString);
            }
        } else {
            setFromDate(e.target.value);
      }
    }

    const handleToDateChange = (e) => {
        let xdString = e.target.value
        if (xdString.indexOf('d') > -1) {
            try {
                xdString = xdString.replace('d', '')
                let xd = Number(xdString)
                let toDate = moment().add(xd, 'days').endOf('day').format("YYYY-MM-DD HH:mm");
                setToDate(toDate);
            } catch (error) {
                setToDate(xdString);
            }
        } else {
            setToDate(e.target.value);
        }
    }


    return (
        <div className="AppRootDesktop">
            <div className='AppRootInnerOverlay'>
                <div className='BodyEdit'>
                    <div className='SchedulePicker'>
                        <h1>Performance Review</h1>
                    </div>
                    <div className='TimeRangePicker'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '48%' }}>
                                <Form.Label>From</Form.Label>
                                <Form.Control type="datetime" value={fromDate} onChange={(e) => handleFromDateChange(e)} />
                                {moment(fromDate, "YYYY-MM-DDTHH:mm").isValid() && (
                                    <div className="friendly-date">
                                        {moment(fromDate).format("dddd, MMMM D, YYYY")}
                                    </div>
                                )}
                            </div>
                            <div style={{ width: '48%' }}>
                                <Form.Label>To</Form.Label>
                                <Form.Control type="datetime" value={toDate} onChange={(e) => handleToDateChange(e)} />
                                {moment(toDate, "YYYY-MM-DDTHH:mm").isValid() && (
                                    <div className="friendly-date">
                                        {moment(toDate).format("dddd, MMMM D, YYYY")}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Form.Label>Search terms</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter search terms"
                                value={searchTerms}
                                onChange={(e) => setSearchTerms(e.target.value)}
                            />
                        </div>
                        <div style={{height: '20px'}}></div>
                    </div>
                    <div className="QuickDateRangePicker">
                        <div className="button-grid">
                            <div className="button-cell">
                                <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('today')}>Today</Button>
                            </div>
                            <div className="button-cell">
                                <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('yesterday')}>Yesterday</Button>
                            </div>
                            <div className="button-cell">
                                <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('this week')}>This Week</Button>
                            </div>
                            <div className="button-cell">
                                <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('last week')}>Last Week</Button>
                            </div>
                            <div className="button-cell">
                                <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('this month')}>This Month</Button>
                            </div>
                            <div className="button-cell">
                                <Button variant="outline-primary" className="w-100" onClick={() => setQuickDateRange('last month')}>Last Month</Button>
                            </div>
                        </div>
                    </div> {/* QuickDateRangePicker */}
                    <div className="DataControls">
                        <Button variant="success" onClick={queryTimerHistory}><FaSearch /> Search</Button>
                        <Button variant="warning" onClick={() => {
                            let startdate = moment(fromDate).toISOString();
                            let enddate = moment(toDate).toISOString();
                            window.open(`https://paymemobile.fr/transactionRecap?token=${token}&startdate=${startdate}&enddate=${enddate}`, '_blank');
                        }}><FaFilePdf /> PDF</Button>
                    </div>
                    <div className="StatsDivvv">
                        {stats.data && stats.data.length > 0 ? stats.data.map((item) => (
                            <div key={item.taskId} onClick={() => item.taskId === taskIdFilter ? setTaskIdFilter(null) : setTaskIdFilter(item.taskId)}>{item.taskId}: {item.durationStr} {item.taskId === taskIdFilter ? <FaFilter /> : ''}</div>
                        )) : <></>}
                        <div className="TotalMinutes">Total: {stats.totalMinutes}</div>
                    </div>

                    <div className="GroupedByDateDivvv">
                        <ReviewCalendarViewChart groupedData={groupedByDate} />
                    </div>

                    <div className="CalendarDivvv">
                    <Fragment>
                        <Calendar
                            components={components}
                            date={moment(defDate).toDate()}
                            events={data}
                            localizer={localizer}
                            max={max}
                            showMultiDayTimes
                            step={15}
                            views={views}
                            popup={true}
                            onNavigate={handleNavigate}
                            onView={(view) => {
                                setDefView(view);
                            }}
                            view={defView}
                            onSelectEvent={(event) => {
                                alert(`Event: ${event.title}`);
                            }}
                            />
                    </Fragment>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Review;
